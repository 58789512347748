<template>
    <div>
        <span>Logging out . . .</span>
    </div>
</template>

<script>
    import firebase from "firebase/compat/app";
    // import 'firebase/compat/auth';

    import {APIService} from "../APIService";
    import * as localforage from "localforage";


    export default {
        mounted() {
            const self = this;
            firebase
                .auth()
                .signOut()
                .then(() => {
                    localStorage.setItem("authenticated", "false");
                    self.$store.commit("setAuthenticated", false);

                    self.logoutFromServer();
                });
        },
        methods: {
            logoutFromServer() {
                const self = this;

                // eslint-disable-next-line no-unused-vars
                APIService.logoutFromServer().then(() => {
                    self.$router.push("login");
                    self.unsetAll();
                    localStorage.setItem("authenticated", "false");

                });
            },
            unsetAll() {
                localStorage.removeItem("attemptedMCQs");
                localStorage.removeItem("authenticated");
                localStorage.removeItem("homedata");
                localStorage.removeItem("ipdetails");
                localStorage.removeItem("isSubscribed");
                localStorage.removeItem("name");
                localStorage.removeItem("patient");
                localStorage.removeItem("photoUrl");
                localStorage.removeItem("playerId");
                localStorage.removeItem("profileUpdated");
                localStorage.removeItem("rapidfireHighScore");
                localStorage.removeItem("token");
                localStorage.removeItem("firebaseui::rememberedAccounts");
                localStorage.removeItem("uid");
                localStorage.removeItem("expiryDate");
                localStorage.removeItem("selfUsername");
                localforage.clear();
            }
        }
    };
</script>